import React, { useMemo } from 'react';

type TwofaInputFieldProps = {
	value: string;
	valueLength: number;
	onChange: (value:string) => void;
}

export default function TwofaInputField(props: TwofaInputFieldProps) {
	const { value, valueLength, onChange } = props;

	const valueSplit = useMemo(() => {
		const valueArray = value.split('');
		// construct array from value
		// arraylength to value length
		const items: string[] = [];
		for (let i = 0; i < valueLength; i++) {
			const char = valueArray[i];
			const re = new RegExp(/^\d+$/);

			if (re.test(char)) {
				items.push(char);
			} else {
				items.push('');
			}
		}
		return items;
	}, [value, valueLength]);

	const focusToNextInput = (target: HTMLInputElement) => {
		let nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

		// skip over the dash
		if (nextElementSibling?.classList.contains('otp-dash')) {
			nextElementSibling = nextElementSibling.nextElementSibling as HTMLInputElement;
		}

		nextElementSibling?.focus();
	};

	const focusToPreviousInput = (target: HTMLInputElement) => {
		let previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

		// skip over the dash
		if (previousElementSibling?.classList.contains('otp-dash')) {
			previousElementSibling = previousElementSibling.previousElementSibling as HTMLInputElement;
		}

		previousElementSibling?.focus();
	};

	const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		let { value: targetValue } = e.target;

		const reg = new RegExp(/^\d+$/);
		const isValueAllDigits = reg.test(targetValue);

		if (targetValue.length !== 0 && !isValueAllDigits) {
			return;
		}

		targetValue = isValueAllDigits
			? targetValue
			: ' ';

		if (targetValue.length === 1) {
			const newValue = value.substring(0, index) + targetValue + value.substring(index + 1);

			onChange(newValue);
			focusToNextInput(e.target);
		} else if (targetValue.length === valueLength) {
			onChange(targetValue);
			e.target.blur();
		}
	};

	const inputOnKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
		const { key } = e;
		const target = e.target as HTMLInputElement;
		const targetValue = target.value;

		if (key === 'ArrowRight' || key === 'ArrowDown') {
			e.preventDefault();
			return focusToNextInput(target);
		}
		if (key === 'ArrowLeft' || key === 'ArrowUp') {
			e.preventDefault();
			return focusToPreviousInput(target);
		}

		target.setSelectionRange(0, targetValue.length);

		if (e.key !== 'Backspace' || target.value !== '') {
			return;
		}
		focusToPreviousInput(target);
	};

	const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		const { target } = e;
		target.setSelectionRange(0, target.value.length);
	};

	const getInputElement = (digit: string, index: number) => {
		return (
			<input
				key={`otp-${index + 1}`}
				type="text"
				inputMode="numeric"
				autoComplete="one-time-code"
				pattern="\d{1}"
				maxLength={1}
				className="otp-input"
				value={digit}
				onChange={e => inputOnChange(e, index)}
				onKeyDown={inputOnKeyDown}
				onFocus={inputOnFocus}
			/>
		);
	};

	const valueItemsMidPoint = Math.floor(valueSplit.length / 2);

	return (
		<div className="twofa-input-field">
			{valueSplit.slice(0, valueItemsMidPoint).map((digit, index) => (
				getInputElement(digit, index)
			))}
			<div className="otp-dash">-</div>
			{valueSplit.slice(valueItemsMidPoint).map((digit, index) => (
				getInputElement(digit, valueItemsMidPoint + index)
			))}
		</div>
	);
}
