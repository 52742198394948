/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsLocationEntity from 'Models/Security/Acl/VisitorsLocationEntity';
import UserLocationEntity from 'Models/Security/Acl/UserLocationEntity';
import AdminLocationEntity from 'Models/Security/Acl/AdminLocationEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ILocationEntityAttributes extends IModelAttributes {
	address: string;
	latitude: number;
	longitude: number;
	mgrs: string;

	nodeId: string;
	node: Models.NodeEntity | Models.INodeEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('LocationEntity', 'Location')
// % protected region % [Customise your entity metadata here] end
export default class LocationEntity extends Model
	implements ILocationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsLocationEntity(),
		new UserLocationEntity(),
		new AdminLocationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Address'] off begin
	@observable
	@attribute<LocationEntity, string>()
	@CRUD({
		name: 'Address',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public address: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address'] end

	// % protected region % [Modify props to the crud options here for attribute 'Latitude'] off begin
	@Validators.Numeric()
	@observable
	@attribute<LocationEntity, number>()
	@CRUD({
		name: 'Latitude',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public latitude: number;
	// % protected region % [Modify props to the crud options here for attribute 'Latitude'] end

	// % protected region % [Modify props to the crud options here for attribute 'Longitude'] off begin
	@Validators.Numeric()
	@observable
	@attribute<LocationEntity, number>()
	@CRUD({
		name: 'Longitude',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public longitude: number;
	// % protected region % [Modify props to the crud options here for attribute 'Longitude'] end

	// % protected region % [Modify props to the crud options here for attribute 'MGRS'] off begin
	@observable
	@attribute<LocationEntity, string>()
	@CRUD({
		name: 'MGRS',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public mgrs: string;
	// % protected region % [Modify props to the crud options here for attribute 'MGRS'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Node'] off begin
		name: 'Node',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.NodeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'nodes',
			oppositeEntity: () => Models.NodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Node'] end
	})
	public nodeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public node: Models.NodeEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ILocationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ILocationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.address !== undefined) {
				this.address = attributes.address;
			}
			if (attributes.latitude !== undefined) {
				this.latitude = attributes.latitude;
			}
			if (attributes.longitude !== undefined) {
				this.longitude = attributes.longitude;
			}
			if (attributes.mgrs !== undefined) {
				this.mgrs = attributes.mgrs;
			}
			if (attributes.nodeId !== undefined) {
				this.nodeId = attributes.nodeId;
			}
			if (attributes.node !== undefined) {
				if (attributes.node === null) {
					this.node = attributes.node;
				} else if (attributes.node instanceof Models.NodeEntity) {
					this.node = attributes.node;
					this.nodeId = attributes.node.id;
				} else {
					this.node = new Models.NodeEntity(attributes.node);
					this.nodeId = this.node.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		node {
			${Models.NodeEntity.getAttributes().join('\n')}
			${Models.NodeEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'node',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(LocationEntity.prototype, 'created');
CRUD(modifiedAttr)(LocationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
