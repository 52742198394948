/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { CaseComparison, CaseComparisonPascalCase, IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { ServerError, ServerParseError } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
// % protected region % [Add extra imports here] on begin
import { useQuery as baseUseQuery } from '@apollo/client';
import {
	DocumentNode, NetworkStatus, OperationVariables, TypedDocumentNode,
} from '@apollo/client/core';
import {
	QueryHookOptions,
	QueryResult,
} from '@apollo/client/react/types/types';
import { useCallback, useEffect, useState } from 'react';
// % protected region % [Add extra imports here] end

/**
 * Check if a GraphQL error is a server error(instead of a GraphQL error)
 * @param error the returned error from server GraphQL
 * @param params The query params to use
 * @returns boolean, if the error is ServerError and have error['statusCode']
 */
export function isServerError(error: Error | ServerError | ServerParseError | undefined | null):
	error is ServerError | ServerParseError {
	return error !== undefined && error !== null && 'statusCode' in error;
}

export function getTheNetworkError(response: ErrorResponse): string | null {
	// Disable dot notation since it is very difficult to write it for type guards
	/* eslint-disable dot-notation */
	if (!!response.networkError && !!response.networkError['result']
		&& !!response.networkError['result']['errors']
		&& !!response.networkError['result']['errors']['length']) {
		const error = response.networkError['result']['errors'][0];
		return error['message'];
	}

	return null;
	/* eslint-enable dot-notation */
}

/**
 * Return an array of error messages from graphQl error response
 * @param response The GraphQL error response
 */
export function getErrorMessages(response: ErrorResponse): string[] {
	if (!!response.response && !!response.response.data) {
		if (!!response.response.data.errors && Array.isArray(response.response.data.errors)) {
			return response.response.data.errors;
		}

		return [JSON.stringify(response.response.data)];
	}
	return [];
}

/**
 * Checks if the condition has any OR conditions
 * A query has OR conditions if it is an array of arrays
 * @param conditions The condition to check
 */
export function isOrCondition<T>(
	conditions: Array<IWhereCondition<T>> | Array<Array<IWhereCondition<T>>> | undefined,
): conditions is Array<Array<IWhereCondition<T>>> {
	if (conditions === undefined) {
		return false;
	}

	if (conditions[0]) {
		return Array.isArray(conditions[0]);
	}
	return false;
}

/**
 * Converts a case comparison to its pascal case version
 * @param comparison The comparison to convert
 */
export function convertCaseComparisonToPascalCase(comparison: CaseComparison): CaseComparisonPascalCase {
	const comparisonMap: {[key in CaseComparison]: CaseComparisonPascalCase} = {
		CURRENT_CULTURE: 'CurrentCulture',
		CURRENT_CULTURE_IGNORE_CASE: 'CurrentCultureIgnoreCase',
		INVARIANT_CULTURE: 'InvariantCulture',
		INVARIANT_CULTURE_IGNORE_CASE: 'InvariantCultureIgnoreCase',
		ORDINAL: 'Ordinal',
		ORDINAL_IGNORE_CASE: 'OrdinalIgnoreCase',
	};

	return comparisonMap[comparison];
}

// % protected region % [Add any extra GraphQL utils here] on begin
export interface GraphQLHookOptions<TResult = any, TData = any, TVariables = OperationVariables>
	extends QueryHookOptions<TData, TVariables>
{
	onCompleted?: (data: TData) => Promise<TResult> | TResult | void;
}

export interface GraphQLResult<TResult = any, TData = any, TVariables = OperationVariables>
	extends QueryResult<TData, TVariables>
{
	result?: TResult;
}

/*
 * The default @appolo.client useQuery hook has some flaws which require the same solution
 * each time it is used. Refactor the repeated code here.
 * Additionally, patch an issue where onComplete was being called on cached results when
 * this is not desired.
 */
export function useQuery<TResult = any, TData = any, TVariables = OperationVariables>(
	query: DocumentNode | TypedDocumentNode<TData, TVariables>,
	options?: GraphQLHookOptions<TResult, TData, TVariables>,
) : GraphQLResult<TResult, TData, TVariables> {
	const [_loading, setLoading] = useState(!options?.skip);
	const [_result, setResult] = useState<TResult>();

	const _onCompleted = useCallback((data: TData) => {
		Promise.resolve(data)
			.then(d => options?.onCompleted?.(d) ?? undefined)
			.then(setResult)
			.finally(() => setLoading(false));
	}, [options]);

	const _options = {
		...options,
		onCompleted: (data: TData): any => {
			if (!_loading) {
				return;
			}

			_onCompleted(data);
		},
		onError: (err: any) => {
			setLoading(false);
			options?.onError?.(err);
		},
	};

	const _return: GraphQLResult<TResult, TData, TVariables> = baseUseQuery<TData, TVariables>(
		query, _options as QueryHookOptions<TData, TVariables>,
	);

	useEffect(() => {
		if (_return.networkStatus !== NetworkStatus.ready) {
			setLoading(true);
		}
	}, [_return.networkStatus]);

	_return.loading = _loading;
	_return.result = _result;

	// @ts-ignore
	if (!_return.refetch._original) {
		// @ts-ignore
		const refetchOriginal = _return.refetch;
		_return.refetch = (...args) => {
			setLoading(true);
			// @ts-ignore
			return refetchOriginal(...args)
				.then(async (data: any) => {
					_onCompleted(data.data);
					return data;
				});
		};
		// @ts-ignore
		_return.refetch._original = refetchOriginal;
	}

	return _return;
}
// % protected region % [Add any extra GraphQL utils here] end
