/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsSystemEntity from 'Models/Security/Acl/VisitorsSystemEntity';
import UserSystemEntity from 'Models/Security/Acl/UserSystemEntity';
import AdminSystemEntity from 'Models/Security/Acl/AdminSystemEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISystemEntityAttributes extends IModelAttributes {
	name: string;
	nodeTypeLabel: string;

	graphs: Array<
		| Models.GraphEntity
		| Models.IGraphEntityAttributes
	>;
	metrics: Array<
		| Models.MetricEntity
		| Models.IMetricEntityAttributes
	>;
	nodeTypes: Array<
		| Models.NodeTypeEntity
		| Models.INodeTypeEntityAttributes
	>;
	organisationId: string;
	organisation: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	timelineTracks: Array<
		| Models.TrackEntity
		| Models.ITrackEntityAttributes
	>;
	rootGraph?: Models.GraphEntity |
		Models.IGraphEntityAttributes;
	systemAttributeItems: Array<
		| Models.SystemSystemAttributeItem
		| Models.ISystemSystemAttributeItemAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SystemEntity', 'System')
// % protected region % [Customise your entity metadata here] end
export default class SystemEntity extends Model
	implements ISystemEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSystemEntity(),
		new UserSystemEntity(),
		new AdminSystemEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<SystemEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Node Type Label'] off begin
	/**
	 * A label to describe the group of configured node types
	 */
	@Validators.Required()
	@observable
	@attribute<SystemEntity, string>()
	@CRUD({
		name: 'Node Type Label',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public nodeTypeLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Node Type Label'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Graph'] off begin
		name: 'Graphs',
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.GraphEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'graphs',
			oppositeEntity: () => Models.GraphEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Graph'] end
	})
	public graphs: Models.GraphEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metric'] off begin
		name: 'Metrics',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.MetricEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'metrics',
			oppositeEntity: () => Models.MetricEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Metric'] end
	})
	public metrics: Models.MetricEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Node Type'] off begin
		name: 'Node Types',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.NodeTypeEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'nodeTypes',
			oppositeEntity: () => Models.NodeTypeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Node Type'] end
	})
	public nodeTypes: Models.NodeTypeEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Timeline Track'] off begin
		name: 'Timeline Tracks',
		displayType: 'reference-multicombobox',
		order: 70,
		referenceTypeFunc: () => Models.TrackEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'timelineTracks',
			oppositeEntity: () => Models.TrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Timeline Track'] end
	})
	public timelineTracks: Models.TrackEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Graph'] off begin
		name: 'Root Graph',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.GraphEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'rootGraphs',
			oppositeEntity: () => Models.GraphEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Root Graph'] end
	})
	public rootGraph?: Models.GraphEntity;

	/**
	 * Attributes that have been assigned to be available for selection within a System
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'System Attribute Item'] off begin
		name: 'System Attribute Item',
		displayType: 'reference-multicombobox',
		order: 90,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.SystemSystemAttributeItem,
		optionEqualFunc: makeJoinEqualsFunc('systemAttributeItemId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'systemEntity',
			oppositeEntityName: 'attributeItemEntity',
			relationName: 'system',
			relationOppositeName: 'systemAttributeItem',
			entity: () => Models.SystemEntity,
			joinEntity: () => Models.SystemSystemAttributeItem,
			oppositeEntity: () => Models.AttributeItemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'System Attribute Item'] end
	})
	public systemAttributeItems: Models.SystemSystemAttributeItem[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISystemEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISystemEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.nodeTypeLabel !== undefined) {
				this.nodeTypeLabel = attributes.nodeTypeLabel;
			}
			if (attributes.graphs !== undefined && Array.isArray(attributes.graphs)) {
				for (const model of attributes.graphs) {
					if (model instanceof Models.GraphEntity) {
						this.graphs.push(model);
					} else {
						this.graphs.push(new Models.GraphEntity(model));
					}
				}
			}
			if (attributes.metrics !== undefined && Array.isArray(attributes.metrics)) {
				for (const model of attributes.metrics) {
					if (model instanceof Models.MetricEntity) {
						this.metrics.push(model);
					} else {
						this.metrics.push(new Models.MetricEntity(model));
					}
				}
			}
			if (attributes.nodeTypes !== undefined && Array.isArray(attributes.nodeTypes)) {
				for (const model of attributes.nodeTypes) {
					if (model instanceof Models.NodeTypeEntity) {
						this.nodeTypes.push(model);
					} else {
						this.nodeTypes.push(new Models.NodeTypeEntity(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.timelineTracks !== undefined && Array.isArray(attributes.timelineTracks)) {
				for (const model of attributes.timelineTracks) {
					if (model instanceof Models.TrackEntity) {
						this.timelineTracks.push(model);
					} else {
						this.timelineTracks.push(new Models.TrackEntity(model));
					}
				}
			}
			if (attributes.rootGraph !== undefined) {
				if (attributes.rootGraph === null) {
					this.rootGraph = attributes.rootGraph;
				} else if (attributes.rootGraph instanceof Models.GraphEntity) {
					this.rootGraph = attributes.rootGraph;
				} else {
					this.rootGraph = new Models.GraphEntity(attributes.rootGraph);
				}
			}
			if (attributes.systemAttributeItems !== undefined && Array.isArray(attributes.systemAttributeItems)) {
				for (const model of attributes.systemAttributeItems) {
					if (model instanceof Models.SystemSystemAttributeItem) {
						this.systemAttributeItems.push(model);
					} else {
						this.systemAttributeItems.push(new Models.SystemSystemAttributeItem(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		systemAttributeItems {
			${Models.SystemSystemAttributeItem.getAttributes().join('\n')}
			systemAttributeItem {
				${Models.AttributeItemEntity.getAttributes().join('\n')}
			}
		}
		graphs {
			${Models.GraphEntity.getAttributes().join('\n')}
		}
		metrics {
			${Models.MetricEntity.getAttributes().join('\n')}
		}
		nodeTypes {
			${Models.NodeTypeEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
		timelineTracks {
			${Models.TrackEntity.getAttributes().join('\n')}
		}
		rootGraph {
			${Models.GraphEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			systemAttributeItems: {},
			graphs: {},
			metrics: {},
			nodeTypes: {},
			timelineTracks: {},
			rootGraph: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'rootGraph',
							'systemAttributeItems',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SystemEntity.prototype, 'created');
CRUD(modifiedAttr)(SystemEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
