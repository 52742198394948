/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsSystemAttributeItemReferenceManyToMany from '../Security/Acl/VisitorsSystemAttributeItemReferenceManyToMany';
import UserSystemAttributeItemReferenceManyToMany from '../Security/Acl/UserSystemAttributeItemReferenceManyToMany';
import AdminSystemAttributeItemReferenceManyToMany from '../Security/Acl/AdminSystemAttributeItemReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISystemSystemAttributeItemAttributes extends IModelAttributes {
	systemId: string;
	systemAttributeItemId: string;

	system: Models.SystemEntity | Models.ISystemEntityAttributes;
	systemAttributeItem: Models.AttributeItemEntity | Models.IAttributeItemEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('SystemSystemAttributeItem')
export default class SystemSystemAttributeItem
	extends Model
	implements ISystemSystemAttributeItemAttributes {
	public static acls: IAcl[] = [
		new VisitorsSystemAttributeItemReferenceManyToMany(),
		new UserSystemAttributeItemReferenceManyToMany(),
		new AdminSystemAttributeItemReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public systemId: string;

	@observable
	@attribute()
	public systemAttributeItemId: string;

	@observable
	@attribute({ isReference: true })
	public system: Models.SystemEntity;

	@observable
	@attribute({ isReference: true })
	public systemAttributeItem: Models.AttributeItemEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ISystemSystemAttributeItemAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.systemId) {
				this.systemId = attributes.systemId;
			}
			if (attributes.systemAttributeItemId) {
				this.systemAttributeItemId = attributes.systemAttributeItemId;
			}

			if (attributes.system) {
				if (attributes.system instanceof Models.SystemEntity) {
					this.system = attributes.system;
					this.systemId = attributes.system.id;
				} else {
					this.system = new Models.SystemEntity(attributes.system);
					this.systemId = this.system.id;
				}
			} else if (attributes.systemId !== undefined) {
				this.systemId = attributes.systemId;
			}

			if (attributes.systemAttributeItem) {
				if (attributes.systemAttributeItem instanceof Models.AttributeItemEntity) {
					this.systemAttributeItem = attributes.systemAttributeItem;
					this.systemAttributeItemId = attributes.systemAttributeItem.id;
				} else {
					this.systemAttributeItem = new Models.AttributeItemEntity(attributes.systemAttributeItem);
					this.systemAttributeItemId = this.systemAttributeItem.id;
				}
			} else if (attributes.systemAttributeItemId !== undefined) {
				this.systemAttributeItemId = attributes.systemAttributeItemId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
