/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsPermissionSchemeEntity from 'Models/Security/Acl/VisitorsPermissionSchemeEntity';
import UserPermissionSchemeEntity from 'Models/Security/Acl/UserPermissionSchemeEntity';
import AdminPermissionSchemeEntity from 'Models/Security/Acl/AdminPermissionSchemeEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { computed } from 'mobx';
// % protected region % [Add any further imports here] end

export interface IPermissionSchemeEntityAttributes extends IModelAttributes {
	name: string;
	createSystem: boolean;
	updateSystem: boolean;
	readEngine1: boolean;
	updateEngine1: boolean;
	readEngine2: boolean;
	readEngine3: boolean;
	updateEngine3: boolean;
	createAPIToken: boolean;
	updateAPIToken: boolean;

	users: Array<
		| Models.UserEntity
		| Models.IUserEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PermissionSchemeEntity', 'Permission Scheme')
// % protected region % [Customise your entity metadata here] end
export default class PermissionSchemeEntity extends Model
	implements IPermissionSchemeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsPermissionSchemeEntity(),
		new UserPermissionSchemeEntity(),
		new AdminPermissionSchemeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute<PermissionSchemeEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Create System'] on begin
	@observable
	private _createSystem: boolean = false;

	@Validators.Required()
	@computed
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Create System',
		displayType: 'checkbox',
		order: 20,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public get createSystem() {
		return this._createSystem;
	}

	public set createSystem(value: boolean) {
		if (!value) {
			this._updateSystem = false;
		}
		this._createSystem = value;
	}
	// % protected region % [Modify props to the crud options here for attribute 'Create System'] end

	// % protected region % [Modify props to the crud options here for attribute 'Update System'] on begin
	@observable
	private _updateSystem: boolean = false;

	@Validators.Required()
	@computed
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Update System',
		displayType: 'checkbox',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public get updateSystem() {
		return this._updateSystem;
	}

	public set updateSystem(value: boolean) {
		this._createSystem = value || this.createSystem;
		this._updateSystem = value;
	}
	// % protected region % [Modify props to the crud options here for attribute 'Update System'] end

	// % protected region % [Modify props to the crud options here for attribute 'Read Engine 1'] on begin
	@observable
	private _readEngine1: boolean = false;

	@Validators.Required()
	@computed
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Read Engine 1',
		displayType: 'checkbox',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public get readEngine1() {
		return this._readEngine1;
	}

	public set readEngine1(value: boolean) {
		if (value === false) {
			this._updateEngine1 = false;
		}
		this._readEngine1 = value;
	}
	// % protected region % [Modify props to the crud options here for attribute 'Read Engine 1'] end

	// % protected region % [Modify props to the crud options here for attribute 'Update Engine 1'] on begin
	@observable
	private _updateEngine1: boolean = false;

	@Validators.Required()
	@computed
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Update Engine 1',
		displayType: 'checkbox',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public get updateEngine1() {
		return this._updateEngine1;
	}

	public set updateEngine1(value: boolean) {
		this.readEngine1 = value || this.readEngine1;
		this._updateEngine1 = value;
	}
	// % protected region % [Modify props to the crud options here for attribute 'Update Engine 1'] end

	// % protected region % [Modify props to the crud options here for attribute 'Read Engine 2'] on begin
	@Validators.Required()
	@observable
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Read Engine 2',
		displayType: 'checkbox',
		order: 60,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public readEngine2: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Read Engine 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Read Engine 3'] on begin
	@observable
	private _readEngine3: boolean = false;

	@Validators.Required()
	@computed
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Read Engine 3',
		displayType: 'checkbox',
		order: 70,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public get readEngine3() {
		return this._readEngine3;
	}

	public set readEngine3(value: boolean) {
		if (value === false) {
			this._updateEngine3 = false;
		}
		this._readEngine3 = value;
	}
	// % protected region % [Modify props to the crud options here for attribute 'Read Engine 3'] end

	// % protected region % [Modify props to the crud options here for attribute 'Update Engine 3'] on begin
	@observable
	private _updateEngine3: boolean = false;

	@Validators.Required()
	@computed
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Update Engine 3',
		displayType: 'checkbox',
		order: 80,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public get updateEngine3() {
		return this._updateEngine3;
	}

	public set updateEngine3(value: boolean) {
		this._readEngine3 = value || this.readEngine3;
		this._updateEngine3 = value;
	}
	// % protected region % [Modify props to the crud options here for attribute 'Update Engine 3'] end

	// % protected region % [Modify props to the crud options here for attribute 'Create API Token'] on begin
	@Validators.Required()
	@observable
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Create API Token',
		displayType: 'checkbox',
		order: 90,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public createAPIToken: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Create API Token'] end

	// % protected region % [Modify props to the crud options here for attribute 'Update API Token'] on begin
	@Validators.Required()
	@observable
	@attribute<PermissionSchemeEntity, boolean>()
	@CRUD({
		name: 'Update API Token',
		displayType: 'checkbox',
		order: 100,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public updateAPIToken: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Update API Token'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'Users',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.UserEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'users',
			oppositeEntity: () => Models.UserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public users: Models.UserEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPermissionSchemeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPermissionSchemeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.createSystem !== undefined) {
				this.createSystem = attributes.createSystem;
			}
			if (attributes.updateSystem !== undefined) {
				this.updateSystem = attributes.updateSystem;
			}
			if (attributes.readEngine1 !== undefined) {
				this.readEngine1 = attributes.readEngine1;
			}
			if (attributes.updateEngine1 !== undefined) {
				this.updateEngine1 = attributes.updateEngine1;
			}
			if (attributes.readEngine2 !== undefined) {
				this.readEngine2 = attributes.readEngine2;
			}
			if (attributes.readEngine3 !== undefined) {
				this.readEngine3 = attributes.readEngine3;
			}
			if (attributes.updateEngine3 !== undefined) {
				this.updateEngine3 = attributes.updateEngine3;
			}
			if (attributes.createAPIToken !== undefined) {
				this.createAPIToken = attributes.createAPIToken;
			}
			if (attributes.updateAPIToken !== undefined) {
				this.updateAPIToken = attributes.updateAPIToken;
			}
			if (attributes.users !== undefined && Array.isArray(attributes.users)) {
				for (const model of attributes.users) {
					if (model instanceof Models.UserEntity) {
						this.users.push(model);
					} else {
						this.users.push(new Models.UserEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		users {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			users: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PermissionSchemeEntity.prototype, 'created');
CRUD(modifiedAttr)(PermissionSchemeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
