/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsGraphEntity from 'Models/Security/Acl/VisitorsGraphEntity';
import UserGraphEntity from 'Models/Security/Acl/UserGraphEntity';
import AdminGraphEntity from 'Models/Security/Acl/AdminGraphEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGraphEntityAttributes extends IModelAttributes {
	name: string;

	systemId: string;
	system: Models.SystemEntity | Models.ISystemEntityAttributes;
	nodes: Array<
		| Models.NodeEntity
		| Models.INodeEntityAttributes
	>;
	rootGraphSystemId?: string;
	rootGraphSystem?: Models.SystemEntity | Models.ISystemEntityAttributes;
	rootNode?: Models.NodeEntity |
		Models.INodeEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('GraphEntity', 'Graph')
// % protected region % [Customise your entity metadata here] end
export default class GraphEntity extends Model
	implements IGraphEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsGraphEntity(),
		new UserGraphEntity(),
		new AdminGraphEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<GraphEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'System'] off begin
		name: 'System',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.SystemEntity,
		// % protected region % [Modify props to the crud options here for reference 'System'] end
	})
	public systemId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public system: Models.SystemEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Node'] off begin
		name: 'Nodes',
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.NodeEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'nodes',
			oppositeEntity: () => Models.NodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Node'] end
	})
	public nodes: Models.NodeEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Graph System'] off begin
		name: 'Root Graph System',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.SystemEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'rootGraphSystems',
			oppositeEntity: () => Models.SystemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Root Graph System'] end
	})
	public rootGraphSystemId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public rootGraphSystem: Models.SystemEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Node'] off begin
		name: 'Root Node',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.NodeEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'rootNodes',
			oppositeEntity: () => Models.NodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Root Node'] end
	})
	public rootNode?: Models.NodeEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IGraphEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IGraphEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.systemId !== undefined) {
				this.systemId = attributes.systemId;
			}
			if (attributes.system !== undefined) {
				if (attributes.system === null) {
					this.system = attributes.system;
				} else if (attributes.system instanceof Models.SystemEntity) {
					this.system = attributes.system;
					this.systemId = attributes.system.id;
				} else {
					this.system = new Models.SystemEntity(attributes.system);
					this.systemId = this.system.id;
				}
			}
			if (attributes.nodes !== undefined && Array.isArray(attributes.nodes)) {
				for (const model of attributes.nodes) {
					if (model instanceof Models.NodeEntity) {
						this.nodes.push(model);
					} else {
						this.nodes.push(new Models.NodeEntity(model));
					}
				}
			}
			if (attributes.rootGraphSystemId !== undefined) {
				this.rootGraphSystemId = attributes.rootGraphSystemId;
			}
			if (attributes.rootGraphSystem !== undefined) {
				if (attributes.rootGraphSystem === null) {
					this.rootGraphSystem = attributes.rootGraphSystem;
				} else if (attributes.rootGraphSystem instanceof Models.SystemEntity) {
					this.rootGraphSystem = attributes.rootGraphSystem;
					this.rootGraphSystemId = attributes.rootGraphSystem.id;
				} else {
					this.rootGraphSystem = new Models.SystemEntity(attributes.rootGraphSystem);
					this.rootGraphSystemId = this.rootGraphSystem.id;
				}
			}
			if (attributes.rootNode !== undefined) {
				if (attributes.rootNode === null) {
					this.rootNode = attributes.rootNode;
				} else if (attributes.rootNode instanceof Models.NodeEntity) {
					this.rootNode = attributes.rootNode;
				} else {
					this.rootNode = new Models.NodeEntity(attributes.rootNode);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		system {
			${Models.SystemEntity.getAttributes().join('\n')}
		}
		nodes {
			${Models.NodeEntity.getAttributes().join('\n')}
			${Models.NodeEntity.getFiles().map(f => f.name).join('\n')}
		}
		rootGraphSystem {
			${Models.SystemEntity.getAttributes().join('\n')}
		}
		rootNode {
			${Models.NodeEntity.getAttributes().join('\n')}
			${Models.NodeEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			nodes: {},
			rootNode: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'rootGraphSystem',
							'rootNode',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public getParentGraphId() {
		if (this.rootNode === undefined) {
			throw new Error('Root node is undefined. Cannot get parent graph id');
		}

		return this.rootNode.graphId;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(GraphEntity.prototype, 'created');
CRUD(modifiedAttr)(GraphEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
