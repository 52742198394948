import React from 'react';
import AchillesLogoImg from '../../../Images/Logos/achilles-logo-white.png';

const AchillesLogo = () => {
	return (
		<img
			alt="logo"
			className="logo"
			src={AchillesLogoImg}
		/>
	);
};

export default AchillesLogo;
