/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsNodeEntity from 'Models/Security/Acl/VisitorsNodeEntity';
import UserNodeEntity from 'Models/Security/Acl/UserNodeEntity';
import AdminNodeEntity from 'Models/Security/Acl/AdminNodeEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import _ from 'lodash';
import { computed } from 'mobx';
import { serialiseDateTime } from 'Util/AttributeUtils';
import * as uuid from 'uuid';
import {
	jsonReplacerFn,
	IModelType,
} from 'Models/Model';
import {
	APPLICATION_ID,
} from 'Symbols';

export type MetricResultColorClass = 'low-score' | 'mid-score' | 'high-score';
// % protected region % [Add any further imports here] end

export interface INodeEntityAttributes extends IModelAttributes {
	value: string;
	notes: string;
	imageId: string;
	image: Blob;

	parentNodeId?: string;
	parentNode?: Models.NodeEntity | Models.INodeEntityAttributes;
	childNodes: Array<
		| Models.NodeEntity
		| Models.INodeEntityAttributes
	>;
	metricResponses: Array<
		| Models.MetricResponseEntity
		| Models.IMetricResponseEntityAttributes
	>;
	graphId: string;
	graph: Models.GraphEntity | Models.IGraphEntityAttributes;
	nodeTypeId: string;
	nodeType: Models.NodeTypeEntity | Models.INodeTypeEntityAttributes;
	trackItems: Array<
		| Models.TrackItemEntity
		| Models.ITrackItemEntityAttributes
	>;
	location?: Models.LocationEntity |
		Models.ILocationEntityAttributes;
	rootGraphId?: string;
	rootGraph?: Models.GraphEntity | Models.IGraphEntityAttributes;
	nodeAttributeItems: Array<
		| Models.NodesNodeAttributeItem
		| Models.INodesNodeAttributeItemAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('NodeEntity', 'Node')
// % protected region % [Customise your entity metadata here] end
export default class NodeEntity extends Model
	implements INodeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsNodeEntity(),
		new UserNodeEntity(),
		new AdminNodeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Value'] off begin
	@observable
	@attribute<NodeEntity, string>()
	@CRUD({
		name: 'Value',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public value: string;
	// % protected region % [Modify props to the crud options here for attribute 'Value'] end

	// % protected region % [Modify props to the crud options here for attribute 'Notes'] off begin
	@observable
	@attribute<NodeEntity, string>()
	@CRUD({
		name: 'Notes',
		displayType: 'textarea',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public notes: string;
	// % protected region % [Modify props to the crud options here for attribute 'Notes'] end

	// % protected region % [Modify props to the crud options here for attribute 'Image'] off begin
	@observable
	@attribute<NodeEntity, string>({ file: 'image' })
	@CRUD({
		name: 'Image',
		displayType: 'file',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'image',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Node" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public imageId: string;

	@observable
	public image: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Image'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Parent Node'] off begin
		name: 'Parent Node',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.NodeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Parent Node'] end
	})
	public parentNodeId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public parentNode: Models.NodeEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Child Node'] off begin
		name: 'Child Nodes',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.NodeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'childNodes',
			oppositeEntity: () => Models.NodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Child Node'] end
	})
	public childNodes: Models.NodeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Metric Response'] off begin
		name: 'Metric Responses',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.MetricResponseEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'metricResponses',
			oppositeEntity: () => Models.MetricResponseEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Metric Response'] end
	})
	public metricResponses: Models.MetricResponseEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Graph'] off begin
		name: 'Graph',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.GraphEntity,
		// % protected region % [Modify props to the crud options here for reference 'Graph'] end
	})
	public graphId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public graph: Models.GraphEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Node Type'] off begin
		name: 'Node Type',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.NodeTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Node Type'] end
	})
	public nodeTypeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public nodeType: Models.NodeTypeEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Track Item'] off begin
		name: 'Track Items',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.TrackItemEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'trackItems',
			oppositeEntity: () => Models.TrackItemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Track Item'] end
	})
	public trackItems: Models.TrackItemEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Location'] off begin
		name: 'Location',
		displayType: 'displayfield',
		order: 100,
		inputProps: {
			displayFunction: (model?: Models.LocationEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'Location'] end
	})
	public location?: Models.LocationEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Graph'] off begin
		name: 'Root Graph',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.GraphEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'rootGraphs',
			oppositeEntity: () => Models.GraphEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Root Graph'] end
	})
	public rootGraphId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public rootGraph: Models.GraphEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Node Attribute Item'] off begin
		name: 'Node Attribute Item',
		displayType: 'reference-multicombobox',
		order: 120,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.NodesNodeAttributeItem,
		optionEqualFunc: makeJoinEqualsFunc('nodeAttributeItemId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'nodeEntity',
			oppositeEntityName: 'attributeItemEntity',
			relationName: 'nodes',
			relationOppositeName: 'nodeAttributeItem',
			entity: () => Models.NodeEntity,
			joinEntity: () => Models.NodesNodeAttributeItem,
			oppositeEntity: () => Models.AttributeItemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Node Attribute Item'] end
	})
	public nodeAttributeItems: Models.NodesNodeAttributeItem[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<INodeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<INodeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.value !== undefined) {
				this.value = attributes.value;
			}
			if (attributes.notes !== undefined) {
				this.notes = attributes.notes;
			}
			if (attributes.image !== undefined) {
				this.image = attributes.image;
			}
			if (attributes.imageId !== undefined) {
				this.imageId = attributes.imageId;
			}
			if (attributes.childNodes !== undefined && Array.isArray(attributes.childNodes)) {
				for (const model of attributes.childNodes) {
					if (model instanceof Models.NodeEntity) {
						this.childNodes.push(model);
					} else {
						this.childNodes.push(new Models.NodeEntity(model));
					}
				}
			}
			if (attributes.parentNodeId !== undefined) {
				this.parentNodeId = attributes.parentNodeId;
			}
			if (attributes.parentNode !== undefined) {
				if (attributes.parentNode === null) {
					this.parentNode = attributes.parentNode;
				} else if (attributes.parentNode instanceof Models.NodeEntity) {
					this.parentNode = attributes.parentNode;
					this.parentNodeId = attributes.parentNode.id;
				} else {
					this.parentNode = new Models.NodeEntity(attributes.parentNode);
					this.parentNodeId = this.parentNode.id;
				}
			}
			if (attributes.metricResponses !== undefined && Array.isArray(attributes.metricResponses)) {
				for (const model of attributes.metricResponses) {
					if (model instanceof Models.MetricResponseEntity) {
						this.metricResponses.push(model);
					} else {
						this.metricResponses.push(new Models.MetricResponseEntity(model));
					}
				}
			}
			if (attributes.graphId !== undefined) {
				this.graphId = attributes.graphId;
			}
			if (attributes.graph !== undefined) {
				if (attributes.graph === null) {
					this.graph = attributes.graph;
				} else if (attributes.graph instanceof Models.GraphEntity) {
					this.graph = attributes.graph;
					this.graphId = attributes.graph.id;
				} else {
					this.graph = new Models.GraphEntity(attributes.graph);
					this.graphId = this.graph.id;
				}
			}
			if (attributes.nodeTypeId !== undefined) {
				this.nodeTypeId = attributes.nodeTypeId;
			}
			if (attributes.nodeType !== undefined) {
				if (attributes.nodeType === null) {
					this.nodeType = attributes.nodeType;
				} else if (attributes.nodeType instanceof Models.NodeTypeEntity) {
					this.nodeType = attributes.nodeType;
					this.nodeTypeId = attributes.nodeType.id;
				} else {
					this.nodeType = new Models.NodeTypeEntity(attributes.nodeType);
					this.nodeTypeId = this.nodeType.id;
				}
			}
			if (attributes.trackItems !== undefined && Array.isArray(attributes.trackItems)) {
				for (const model of attributes.trackItems) {
					if (model instanceof Models.TrackItemEntity) {
						this.trackItems.push(model);
					} else {
						this.trackItems.push(new Models.TrackItemEntity(model));
					}
				}
			}
			if (attributes.location !== undefined) {
				if (attributes.location === null) {
					this.location = attributes.location;
				} else if (attributes.location instanceof Models.LocationEntity) {
					this.location = attributes.location;
				} else {
					this.location = new Models.LocationEntity(attributes.location);
				}
			}
			if (attributes.rootGraphId !== undefined) {
				this.rootGraphId = attributes.rootGraphId;
			}
			if (attributes.rootGraph !== undefined) {
				if (attributes.rootGraph === null) {
					this.rootGraph = attributes.rootGraph;
				} else if (attributes.rootGraph instanceof Models.GraphEntity) {
					this.rootGraph = attributes.rootGraph;
					this.rootGraphId = attributes.rootGraph.id;
				} else {
					this.rootGraph = new Models.GraphEntity(attributes.rootGraph);
					this.rootGraphId = this.rootGraph.id;
				}
			}
			if (attributes.nodeAttributeItems !== undefined && Array.isArray(attributes.nodeAttributeItems)) {
				for (const model of attributes.nodeAttributeItems) {
					if (model instanceof Models.NodesNodeAttributeItem) {
						this.nodeAttributeItems.push(model);
					} else {
						this.nodeAttributeItems.push(new Models.NodesNodeAttributeItem(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		nodeAttributeItems {
			${Models.NodesNodeAttributeItem.getAttributes().join('\n')}
			nodeAttributeItem {
				${Models.AttributeItemEntity.getAttributes().join('\n')}
			}
		}
		childNodes {
			${Models.NodeEntity.getAttributes().join('\n')}
			${Models.NodeEntity.getFiles().map(f => f.name).join('\n')}
		}
		parentNode {
			${Models.NodeEntity.getAttributes().join('\n')}
			${Models.NodeEntity.getFiles().map(f => f.name).join('\n')}
		}
		metricResponses {
			${Models.MetricResponseEntity.getAttributes().join('\n')}
		}
		graph {
			${Models.GraphEntity.getAttributes().join('\n')}
		}
		nodeType {
			${Models.NodeTypeEntity.getAttributes().join('\n')}
		}
		trackItems {
			${Models.TrackItemEntity.getAttributes().join('\n')}
		}
		location {
			${Models.LocationEntity.getAttributes().join('\n')}
		}
		rootGraph {
			${Models.GraphEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			nodeAttributeItems: {},
			childNodes: {},
			metricResponses: {},
			trackItems: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'childNodes',
							'rootGraph',
							'nodeAttributeItems',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.value || '';
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	// returns true if node is a root node in any graph
	@computed
	public get isRootNode(): boolean {
		return !!this.rootGraphId;
	}

	public isRootNodeOfGraph(graphId: string): boolean {
		return !!(this.rootGraphId && this.rootGraphId === graphId);
	}

	@computed
	public get isSystemRootNode(): boolean { // if node is root system node
		if (!this.graphId) {
			throw new Error('Cannot determine if node is system root - missing graph id');
		}
		/**
		 * Normally a node 'belongs' to a graph by a relation to graph via graphId property,
		 * and if it is the root node of a subanalysis it's rootGraphId points to that GraphEntity
		 *
		 * The exception is root system nodes, where they are the root node of the root graph. In this case, graphId and rootGraphId are the same id,
		 * and we can use this to determine if the node is the root system node
		 */
		return !!(this.rootGraphId && this.rootGraphId === this.graphId);
	}

	@computed
	public get isSubanalysisRootNode(): boolean { // if node is root node for a subanalysis
		if (!this.graphId) {
			throw new Error('Cannot determine if node is subanalysis root - missing graph id');
		}
		return !!(this.rootGraphId && !this.isSystemRootNode);
	}

	@computed
	public get isChildNode(): boolean { // if not is NOT a root node for either system or subanalysis
		if (!this.graphId) {
			throw new Error('Cannot determine if node is a child node - missing graph id');
		}
		return !!(!this.rootGraphId && this.graphId);
	}

	@computed
	public get hasChildren(): boolean {
		return this.childNodes.length > 0;
	}

	public belongsToGraph(graphId: string) {
		return this.graphId === graphId;
	}

	/**
	 * Can Node have a subanalysis created for it right now?
	 * @param analysisDepth
	 * @returns boolean
	 */
	public canAddSubanalysis(nodeLevel: number): boolean {
		return this.isChildNode && !this.isSystemRootNode && !this.isSubanalysisRootNode
			&& (nodeLevel === 1 || nodeLevel === 2);
	}

	public getCarverScores(
		getMetricOptionEntityById: (id: string) => Models.MetricOptionEntity | undefined,
		maxScore: number,
	): {
		aggregatedScore: number,
		percentageScore: number,
		colorClass: MetricResultColorClass,
	} {
		if (this.metricResponses.length === 0) {
			return {
				aggregatedScore: 0,
				percentageScore: 0,
				colorClass: 'low-score',
			};
		}

		let aggregatedScore: number = 0;

		for (const metricResponse of this.metricResponses) {
			const metricOption = getMetricOptionEntityById(metricResponse.metricOptionId);
			if (metricOption === undefined) {
				throw new Error('Cannot find metric option value');
			}
			aggregatedScore += metricOption.value;
		}

		const percentageScore = Math.round((aggregatedScore / maxScore) * 100);

		let colorClass: MetricResultColorClass;

		if (percentageScore < 50) {
			colorClass = 'low-score';
		} else if (percentageScore >= 75) {
			colorClass = 'high-score';
		} else {
			colorClass = 'mid-score';
		}

		return {
			aggregatedScore,
			percentageScore,
			colorClass,
		};
	}

	/**
	 * Returns true if provided attribute is assigned to this node
	 */
	public hasAnyOfAttributeItems(attributeItemIds: Set<string>): boolean {
		return !!this.nodeAttributeItems
			.find(item => attributeItemIds.has(item.nodeAttributeItemId));
	}

	/**
	 * Override default toJSON to handle existing file blobs
	 */
	public toJSON(
		path: ReferencePath = {},
		excludeCrudFields = false,
		replacer: jsonReplacerFn | undefined = undefined,
	) {
		const json = {};
		const pathKeys = Object.keys(path);

		let allKeys = _.uniq(this.attributes.concat(this.references).concat(pathKeys));
		if (excludeCrudFields) {
			const staticType = this.constructor as IModelType;
			const excludeList = this.id
				? staticType.excludeFromUpdate
				: staticType.excludeFromCreate;
			allKeys = allKeys.filter((k: string) => excludeList.indexOf(k) === -1);
		}

		for (const key of allKeys) {
			if (this[key] === null && this.attributes.indexOf(key) !== -1) {
				json[key] = null;
				// eslint-disable-next-line no-continue
				continue;
			}

			const serialiseData = this.serialiseAsData.find(x => x.name === key);
			if (serialiseData) {
				json[key] = serialiseData.func(this, this[key]);
				// eslint-disable-next-line no-continue
				continue;
			}

			switch (typeof (this[key])) {
				case 'function':
					// We never want functions
					break;
				case 'object':
					// Format dates as strings
					if (this[key] instanceof Date) {
						json[key] = serialiseDateTime(this[key]);
						break;
					}

					// We only want objects if they are defined in the provided path
					if (pathKeys.indexOf(key) >= 0) {
						if (Array.isArray(this[key])) {
							json[key] = this[key].map((model: Model | { [key: string]: unknown }) => {
								if (typeof model.toJSON === 'function') {
									return model.toJSON(path[key], true);
								}
								return JSON.parse(JSON.stringify(model));
							});
						} else if (this[key] === null) {
							json[key] = null;
						} else if (typeof this[key].toJSON === 'function') {
							json[key] = this[key].toJSON(path[key], true);
						} else {
							json[key] = JSON.parse(JSON.stringify(this[key]));
						}
					}
					break;
				case 'undefined':
					break;
				default:
					if (!key.startsWith('_')) {
						json[key] = this[key];
					}
			}
		}

		for (const file of this.files) {
			const fileBlob = this[file.blob];
			// do not set a new uuid if the file name property already has a uuid - it has likely been set elsewhere
			if (fileBlob instanceof Blob && !this[file.name]) {
				json[file.name] = uuid.v5(`${this._clientId}.${file.name}`, APPLICATION_ID);
			} else if (this[file.name]) {
				json[file.name] = this[file.name];
			}
		}

		if (replacer) {
			return replacer(json);
		}
		return json;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(NodeEntity.prototype, 'created');
CRUD(modifiedAttr)(NodeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
