/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsNodeAttributeItemReferenceManyToMany from '../Security/Acl/VisitorsNodeAttributeItemReferenceManyToMany';
import UserNodeAttributeItemReferenceManyToMany from '../Security/Acl/UserNodeAttributeItemReferenceManyToMany';
import AdminNodeAttributeItemReferenceManyToMany from '../Security/Acl/AdminNodeAttributeItemReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface INodesNodeAttributeItemAttributes extends IModelAttributes {
	nodesId: string;
	nodeAttributeItemId: string;

	nodes: Models.NodeEntity | Models.INodeEntityAttributes;
	nodeAttributeItem: Models.AttributeItemEntity | Models.IAttributeItemEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('NodesNodeAttributeItem')
export default class NodesNodeAttributeItem
	extends Model
	implements INodesNodeAttributeItemAttributes {
	public static acls: IAcl[] = [
		new VisitorsNodeAttributeItemReferenceManyToMany(),
		new UserNodeAttributeItemReferenceManyToMany(),
		new AdminNodeAttributeItemReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public nodesId: string;

	@observable
	@attribute()
	public nodeAttributeItemId: string;

	@observable
	@attribute({ isReference: true })
	public nodes: Models.NodeEntity;

	@observable
	@attribute({ isReference: true })
	public nodeAttributeItem: Models.AttributeItemEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<INodesNodeAttributeItemAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.nodesId) {
				this.nodesId = attributes.nodesId;
			}
			if (attributes.nodeAttributeItemId) {
				this.nodeAttributeItemId = attributes.nodeAttributeItemId;
			}

			if (attributes.nodes) {
				if (attributes.nodes instanceof Models.NodeEntity) {
					this.nodes = attributes.nodes;
					this.nodesId = attributes.nodes.id;
				} else {
					this.nodes = new Models.NodeEntity(attributes.nodes);
					this.nodesId = this.nodes.id;
				}
			} else if (attributes.nodesId !== undefined) {
				this.nodesId = attributes.nodesId;
			}

			if (attributes.nodeAttributeItem) {
				if (attributes.nodeAttributeItem instanceof Models.AttributeItemEntity) {
					this.nodeAttributeItem = attributes.nodeAttributeItem;
					this.nodeAttributeItemId = attributes.nodeAttributeItem.id;
				} else {
					this.nodeAttributeItem = new Models.AttributeItemEntity(attributes.nodeAttributeItem);
					this.nodeAttributeItemId = this.nodeAttributeItem.id;
				}
			} else if (attributes.nodeAttributeItemId !== undefined) {
				this.nodeAttributeItemId = attributes.nodeAttributeItemId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
