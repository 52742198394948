/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsAttributeItemEntity from 'Models/Security/Acl/VisitorsAttributeItemEntity';
import UserAttributeItemEntity from 'Models/Security/Acl/UserAttributeItemEntity';
import AdminAttributeItemEntity from 'Models/Security/Acl/AdminAttributeItemEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { escape } from 'lodash';
import { computed } from 'mobx';
// % protected region % [Add any further imports here] end

export interface IAttributeItemEntityAttributes extends IModelAttributes {
	name: string;
	icon: string;
	organisationDefault: boolean;

	organisationId: string;
	organisation: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	parentAttributeId?: string;
	parentAttribute?: Models.AttributeItemEntity | Models.IAttributeItemEntityAttributes;
	childAttributeItems: Array<
		| Models.AttributeItemEntity
		| Models.IAttributeItemEntityAttributes
	>;
	attributeGroupId?: string;
	attributeGroup?: Models.AttributeItemEntity | Models.IAttributeItemEntityAttributes;
	groupedAttributeItems: Array<
		| Models.AttributeItemEntity
		| Models.IAttributeItemEntityAttributes
	>;
	rootOrganisationId?: string;
	rootOrganisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	nodess: Array<
		| Models.NodesNodeAttributeItem
		| Models.INodesNodeAttributeItemAttributes
	>;
	systems: Array<
		| Models.SystemSystemAttributeItem
		| Models.ISystemSystemAttributeItemAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AttributeItemEntity', 'Attribute Item')
// % protected region % [Customise your entity metadata here] end
export default class AttributeItemEntity extends Model
	implements IAttributeItemEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAttributeItemEntity(),
		new UserAttributeItemEntity(),
		new AdminAttributeItemEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<AttributeItemEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Icon'] off begin
	/**
	 * The icon displayed if this attribute item is a top-level item
	 */
	@observable
	@attribute<AttributeItemEntity, string>()
	@CRUD({
		name: 'Icon',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public icon: string;
	// % protected region % [Modify props to the crud options here for attribute 'Icon'] end

	// % protected region % [Modify props to the crud options here for attribute 'Organisation Default'] off begin
	/**
	 * Default attribute for organisation
	 */
	@observable
	@attribute<AttributeItemEntity, boolean>()
	@CRUD({
		name: 'Organisation Default',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public organisationDefault: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Organisation Default'] end

	/**
	 * Shared pool of Attributes created for an Organisation
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Parent Attribute'] off begin
		name: 'Parent Attribute',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.AttributeItemEntity,
		// % protected region % [Modify props to the crud options here for reference 'Parent Attribute'] end
	})
	public parentAttributeId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public parentAttribute: Models.AttributeItemEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Child Attribute Item'] off begin
		name: 'Child Attribute Items',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.AttributeItemEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'childAttributeItems',
			oppositeEntity: () => Models.AttributeItemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Child Attribute Item'] end
	})
	public childAttributeItems: Models.AttributeItemEntity[] = [];

	/**
	 * Attribute items that belong to an Attribute Group
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Attribute Group'] off begin
		name: 'Attribute Group',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.AttributeItemEntity,
		// % protected region % [Modify props to the crud options here for reference 'Attribute Group'] end
	})
	public attributeGroupId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public attributeGroup: Models.AttributeItemEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Grouped Attribute Item'] off begin
		name: 'Grouped Attribute Items',
		displayType: 'reference-multicombobox',
		order: 80,
		referenceTypeFunc: () => Models.AttributeItemEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'groupedAttributeItems',
			oppositeEntity: () => Models.AttributeItemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Grouped Attribute Item'] end
	})
	public groupedAttributeItems: Models.AttributeItemEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Organisation'] off begin
		name: 'Root Organisation',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Root Organisation'] end
	})
	public rootOrganisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public rootOrganisation: Models.OrganisationEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Nodes'] off begin
		name: 'Nodes',
		displayType: 'reference-multicombobox',
		order: 100,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.NodesNodeAttributeItem,
		optionEqualFunc: makeJoinEqualsFunc('nodesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'attributeItemEntity',
			oppositeEntityName: 'nodeEntity',
			relationName: 'nodeAttributeItem',
			relationOppositeName: 'nodes',
			entity: () => Models.AttributeItemEntity,
			joinEntity: () => Models.NodesNodeAttributeItem,
			oppositeEntity: () => Models.NodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Nodes'] end
	})
	public nodess: Models.NodesNodeAttributeItem[] = [];

	/**
	 * Attributes that have been assigned to be available for selection within a System
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'System'] off begin
		name: 'System',
		displayType: 'reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.SystemSystemAttributeItem,
		optionEqualFunc: makeJoinEqualsFunc('systemId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'attributeItemEntity',
			oppositeEntityName: 'systemEntity',
			relationName: 'systemAttributeItem',
			relationOppositeName: 'system',
			entity: () => Models.AttributeItemEntity,
			joinEntity: () => Models.SystemSystemAttributeItem,
			oppositeEntity: () => Models.SystemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'System'] end
	})
	public systems: Models.SystemSystemAttributeItem[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAttributeItemEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAttributeItemEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.icon !== undefined) {
				this.icon = attributes.icon;
			}
			if (attributes.organisationDefault !== undefined) {
				this.organisationDefault = attributes.organisationDefault;
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.childAttributeItems !== undefined && Array.isArray(attributes.childAttributeItems)) {
				for (const model of attributes.childAttributeItems) {
					if (model instanceof Models.AttributeItemEntity) {
						this.childAttributeItems.push(model);
					} else {
						this.childAttributeItems.push(new Models.AttributeItemEntity(model));
					}
				}
			}
			if (attributes.parentAttributeId !== undefined) {
				this.parentAttributeId = attributes.parentAttributeId;
			}
			if (attributes.parentAttribute !== undefined) {
				if (attributes.parentAttribute === null) {
					this.parentAttribute = attributes.parentAttribute;
				} else if (attributes.parentAttribute instanceof Models.AttributeItemEntity) {
					this.parentAttribute = attributes.parentAttribute;
					this.parentAttributeId = attributes.parentAttribute.id;
				} else {
					this.parentAttribute = new Models.AttributeItemEntity(attributes.parentAttribute);
					this.parentAttributeId = this.parentAttribute.id;
				}
			}
			if (attributes.groupedAttributeItems !== undefined && Array.isArray(attributes.groupedAttributeItems)) {
				for (const model of attributes.groupedAttributeItems) {
					if (model instanceof Models.AttributeItemEntity) {
						this.groupedAttributeItems.push(model);
					} else {
						this.groupedAttributeItems.push(new Models.AttributeItemEntity(model));
					}
				}
			}
			if (attributes.attributeGroupId !== undefined) {
				this.attributeGroupId = attributes.attributeGroupId;
			}
			if (attributes.attributeGroup !== undefined) {
				if (attributes.attributeGroup === null) {
					this.attributeGroup = attributes.attributeGroup;
				} else if (attributes.attributeGroup instanceof Models.AttributeItemEntity) {
					this.attributeGroup = attributes.attributeGroup;
					this.attributeGroupId = attributes.attributeGroup.id;
				} else {
					this.attributeGroup = new Models.AttributeItemEntity(attributes.attributeGroup);
					this.attributeGroupId = this.attributeGroup.id;
				}
			}
			if (attributes.rootOrganisationId !== undefined) {
				this.rootOrganisationId = attributes.rootOrganisationId;
			}
			if (attributes.rootOrganisation !== undefined) {
				if (attributes.rootOrganisation === null) {
					this.rootOrganisation = attributes.rootOrganisation;
				} else if (attributes.rootOrganisation instanceof Models.OrganisationEntity) {
					this.rootOrganisation = attributes.rootOrganisation;
					this.rootOrganisationId = attributes.rootOrganisation.id;
				} else {
					this.rootOrganisation = new Models.OrganisationEntity(attributes.rootOrganisation);
					this.rootOrganisationId = this.rootOrganisation.id;
				}
			}
			if (attributes.nodess !== undefined && Array.isArray(attributes.nodess)) {
				for (const model of attributes.nodess) {
					if (model instanceof Models.NodesNodeAttributeItem) {
						this.nodess.push(model);
					} else {
						this.nodess.push(new Models.NodesNodeAttributeItem(model));
					}
				}
			}
			if (attributes.systems !== undefined && Array.isArray(attributes.systems)) {
				for (const model of attributes.systems) {
					if (model instanceof Models.SystemSystemAttributeItem) {
						this.systems.push(model);
					} else {
						this.systems.push(new Models.SystemSystemAttributeItem(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		nodess {
			${Models.NodesNodeAttributeItem.getAttributes().join('\n')}
			nodes {
				${Models.NodeEntity.getAttributes().join('\n')}
				${Models.NodeEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		systems {
			${Models.SystemSystemAttributeItem.getAttributes().join('\n')}
			system {
				${Models.SystemEntity.getAttributes().join('\n')}
			}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
		childAttributeItems {
			${Models.AttributeItemEntity.getAttributes().join('\n')}
		}
		parentAttribute {
			${Models.AttributeItemEntity.getAttributes().join('\n')}
		}
		groupedAttributeItems {
			${Models.AttributeItemEntity.getAttributes().join('\n')}
		}
		attributeGroup {
			${Models.AttributeItemEntity.getAttributes().join('\n')}
		}
		rootOrganisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			nodess: {},
			systems: {},
			childAttributeItems: {},
			groupedAttributeItems: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'childAttributeItems',
							'groupedAttributeItems',
							'nodess',
							'systems',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	@computed
	public get isGroup() {
		return !!this.rootOrganisationId;
	}

	public getIconName() {
		if (this.icon) {
			return escape(this.icon);
		}

		if (this.attributeGroup?.icon) {
			return escape(this.attributeGroup.icon);
		}

		return '';
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AttributeItemEntity.prototype, 'created');
CRUD(modifiedAttr)(AttributeItemEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
