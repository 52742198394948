import React from 'react';
import KBRLogoWhite from '../../Images/Logos/kbr-logo-white.png';

const KBRPageLogo = () => {
	return (
		<div className="kbr-logo-container">
			<span className="font--body-small">Powered by</span>
			<img src={KBRLogoWhite} alt="kbr logo white" draggable="false" />
		</div>
	);
};

export default KBRPageLogo;
